import React from 'react'
import {
  SimpleForm,
  TextInput,
  Create,
  SelectInput,
  required,
  useNotify,
  useRedirect,
  FileInput,
  FileField,
} from 'react-admin'

const PdfFilesCreate = (props) => {
  const notify = useNotify()
  const redirect = useRedirect()
  const onSuccess = ({ data }) => {
    notify('ra.notification.created', 'info', { smart_count: 1 })
    redirect('list', props.basePath, data.id, data)
  }

  return (
    <Create {...props} onSuccess={onSuccess}>
      <SimpleForm>
        <TextInput source="title" validate={required()} fullWidth />
        <TextInput source="identificator" fullWidth />
        <FileInput
          source="file.recordFile"
          multiple={false}
          accept="application/pdf"
          validate={required()}
        >
          <FileField source="src" title="title" />
        </FileInput>
      </SimpleForm>
    </Create>
  )
}

export default PdfFilesCreate
