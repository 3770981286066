import React, { useState, useEffect } from 'react'

import IconImageEye from '@material-ui/icons/RemoveRedEye'
import { Button, useGetOne, useDataProvider } from 'react-admin'
import { base64StringToBlob } from 'blob-util'
import { CircularProgress } from '@material-ui/core'

const CertificateQuickPreviewButton = ({ id }) => {
  const [pdf, setPdf] = useState(false)
  const { data } = useGetOne('certificates', id)
  const dataProvider = useDataProvider()

  const handleClick = () => {
    const blob = new Blob([base64StringToBlob(pdf)], { type: 'application/pdf' })

    const blobURL = URL.createObjectURL(blob)

    window.open(blobURL, '_blank')
  }

  useEffect(async () => {
    setPdf(false)
    if (data) {
      const { data: r } = await dataProvider.pdfExample(data.id)
      if (r.pdf) {
        setPdf(r.pdf)
      }
    }
  }, [data])

  return (
    <>
      <Button onClick={handleClick} label="ra.action.show" disabled={!pdf}>
        {!pdf ? <CircularProgress size={14} /> : <IconImageEye />}
      </Button>
    </>
  )
}

export default CertificateQuickPreviewButton
