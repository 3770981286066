import { ReferenceInput, SelectInput, required } from 'react-admin'
import { useState, useCallback } from 'react'
import { useFormState } from 'react-final-form'
import { makeStyles } from '@material-ui/core/styles'
import { servicesHost } from '../../utils/dataprovider'
import VideoMediaSourceCreateButton from './VideoMediaSourceCreateButton'
import VideoMediaSourceQuickPreviewButton from './VideoMediaSourceQuickPreviewButton'
import VideoMediaSourceFetchThumbnail from './VideoMediaSourceFetchThumbnail'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
})

const spySubscription = { values: true }

const VideoMediaSourceReferenceInput = (props) => {
  const classes = useStyles()
  const [version, setVersion] = useState(0)
  const { values } = useFormState({ subscription: spySubscription })
  const handleChange = useCallback(() => setVersion(version + 1), [version])

  return (
    <div className={classes.root}>
      <ReferenceInput
        key={version}
        {...props}
        source="videoSource.id"
        reference="video-media-sources"
        allowEmpty
        validate={required()}
      >
        <SelectInput optionText="url" />
      </ReferenceInput>

      <VideoMediaSourceCreateButton onChange={handleChange} />
      {!!values.videoSource && (
        <VideoMediaSourceQuickPreviewButton
          id={values.videoSource.id ? values.videoSource.id : values.videoSource}
        />
      )}
      {!!values.videoSource && <VideoMediaSourceFetchThumbnail videoSource={values.videoSource} />}
    </div>
  )
}

export default VideoMediaSourceReferenceInput
