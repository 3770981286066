import React from 'react'
import { SimpleForm, TextInput, Edit, SelectInput, required, BooleanInput } from 'react-admin'
import ImagesReferenceInput from '../common/ImagesReferenceInput'

const MediaAuthorsEdit = (props) => {
  const transform = (data) => {
    const newData = {}

    Object.keys(data).forEach((i) => {
      if (typeof data[i] === 'object' && data[i].id) {
        newData[i] = data[i].id
      } else {
        newData[i] = data[i]
      }
    })

    if (newData.id) {
      delete newData.id
    }

    return newData
  }

  return (
    <Edit {...props} transform={transform}>
      <SimpleForm>
        <TextInput source="title" />
        <TextInput source="link" />
        <TextInput source="position" />
        <TextInput source="subTitle" fullWidth />
        <TextInput source="description" multiline fullWidth />
        <TextInput source="quotation" multiline fullWidth />
        <BooleanInput label="isEnabled" source="isEnabled" />
        <BooleanInput label="isEnabled2" source="isEnabled2" />
        <ImagesReferenceInput />
      </SimpleForm>
    </Edit>
  )
}

export default MediaAuthorsEdit
