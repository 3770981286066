import { ReferenceInput, SelectInput, required } from 'react-admin'
import { useState, useCallback } from 'react'
import { useFormState } from 'react-final-form'
import { makeStyles } from '@material-ui/core/styles'
import PresentationMediaSourceQuickCreateButton from './PresentationMediaSourceQuickCreateButton'
import PresentationMediaSourceQuickPreviewButton from './PresentationMediaSourceQuickPreviewButton'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
})

const spySubscription = { values: true }

const PresentationMediaSourceReferenceInput = (props) => {
  const classes = useStyles()
  const [version, setVersion] = useState(0)
  const { values } = useFormState({ subscription: spySubscription })
  const handleChange = useCallback(() => setVersion(version + 1), [version])

  return (
    <div className={classes.root}>
      <ReferenceInput
        key={version}
        {...props}
        source="presentationSource.id"
        reference="presentation-media-sources"
        allowEmpty
        validate={required()}
      >
        <SelectInput optionText="title" />
      </ReferenceInput>

      <PresentationMediaSourceQuickCreateButton onChange={handleChange} />
      {/* {!!values.presentationSource && (
        <PresentationMediaSourceQuickPreviewButton id={values.presentationSource} />
      )} */}
    </div>
  )
}

export default PresentationMediaSourceReferenceInput
