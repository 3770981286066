import * as React from 'react'
import {
  Show,
  TextField,
  BooleanField,
  DateField,
  TabbedShowLayout,
  EmailField,
  ReferenceField,
  ReferenceManyField,
  SingleFieldList,
  ChipField,
  ShowButton,
  Tab,
  Datagrid,
  DeleteButton,
  RefreshButton,
  TopToolbar,
} from 'react-admin'
import Grid from '@material-ui/core/Grid'

const CommentsShowActions = ({ basePath, data, resource }) => (
  <TopToolbar>
    <DeleteButton basePath={basePath} record={data} />
    <RefreshButton basePath={basePath} record={data} />
  </TopToolbar>
)

const CommentsShow = (props) => (
  <Show {...props} actions={<CommentsShowActions />}>
    <TabbedShowLayout>
      <Tab label="main">
        <TextField source="id" />
        <TextField source="content" />
        <DateField label="Created At" source="createdAt" />
        <DateField label="Created At" source="updatedAt" />
      </Tab>
      <Tab label="relations">
        <ReferenceField label="Parent comment" source="parent.id" reference="comments" link="show">
          <TextField source="content" />
        </ReferenceField>
        <ReferenceManyField label="Child comments" reference="comments" target="parent">
          <Datagrid>
            <DateField source="createdAt" />
            <ReferenceField label="User" source="creator.id" reference="users" link="show">
              <TextField source="email" />
            </ReferenceField>
            <TextField source="content" />
            <ShowButton />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
)

export default CommentsShow
