import { TextField, BooleanField, Datagrid, List, DateField } from 'react-admin'

const ProductsList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="title" />
      <DateField source="createdAt" />
    </Datagrid>
  </List>
)

export default ProductsList
