import React from 'react'
import {
  SimpleForm,
  TextInput,
  Create,
  SelectInput,
  required,
  useNotify,
  useRedirect,
} from 'react-admin'
import { SETTINGS_TYPE } from '../../constants'

const typeChoices = SETTINGS_TYPE.map((item, index) => ({
  id: index,
  name: item,
}))

const SettingsCreate = (props) => {
  const notify = useNotify()
  const redirect = useRedirect()
  const onSuccess = ({ data }) => {
    notify('ra.notification.created', 'info', { smart_count: 1 })
    redirect('list', props.basePath, data.id, data)
  }

  return (
    <Create {...props} onSuccess={onSuccess}>
      <SimpleForm>
        <SelectInput source="type" validate={[required()]} choices={typeChoices} />
        <TextInput fullWidth={true} source="value" />
      </SimpleForm>
    </Create>
  )
}

export default SettingsCreate
