import { Edit, useNotify, useRedirect } from 'react-admin'

const ModulesEditHOC = (WrappedComponent) => {
  return (props) => {
    const transform = (data) => {
      const newData = {}

      Object.keys(data).forEach((i) => {
        if (i === 'mediaGroups') {
          for (const [key, value] of Object.entries(data[i])) {
            if (!data[i][key]) {
              delete data[i][key]
              continue
            }
            let newItem = {
              ...data[i][key],
              media: data[i][key].media.map((item) => item.id),
              surveys: data[i][key].surveys.map((item) => item.id),
            }
            data[i][key] = newItem
          }

          newData[i] = data[i]
        } else if (Array.isArray(data[i])) {
          newData[i] = data[i].map((item) => (item.id ? item.id : item))
        } else if (typeof data[i] === 'object' && data[i].id) {
          newData[i] = data[i].id
        } else {
          newData[i] = data[i]
        }
      })

      if (newData.id) {
        delete newData.id
      }

      return newData
    }

    return (
      <Edit {...props} transform={transform} mutationMode="pessimistic">
        <WrappedComponent />
      </Edit>
    )
  }
}

export default ModulesEditHOC
