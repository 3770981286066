import { servicesHost } from './dataprovider'

const refreshTokenHandler = () => {
  let timeout
  let isRefreshing = null
  let delay = 3600

  const waitForTokenRefresh = () => {
    if (!isRefreshing) {
      return Promise.resolve()
    }

    return isRefreshing
  }

  const subscribeRefreshing = () => {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      const request = new Request(`${servicesHost.host}/api/token/refresh`, {
        method: 'POST',
        body: JSON.stringify({
          refresh_token: JSON.parse(localStorage.getItem('auth')).refresh_token,
        }),
        headers: new Headers({ 'Content-Type': 'application/json' }),
      })

      isRefreshing = fetch(request)
        .then((response) => {
          if (response.status < 200 || (response.status >= 300 && response.status < 400)) {
            throw new Error(response.statusText)
          }
          return response.json()
        })
        .then((auth) => {
          if (auth.code) {
            throw new Error(auth.message)
          }
          localStorage.setItem('auth', JSON.stringify(auth))
          subscribeRefreshing()
          isRefreshing = null
        })
        .catch((response) => {
          isRefreshing = null
          throw new Error(response ? response : 'Network error')
        })
    }, delay * 1000 - 5000)

    return isRefreshing
  }

  return {
    subscribeRefreshing,
    waitForTokenRefresh,
  }
}

export default refreshTokenHandler()
