import { TextField, BooleanField, Datagrid, List } from 'react-admin'

const PagesList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="identificator" />
    </Datagrid>
  </List>
)

export default PagesList
