import {
  TextInput,
  TabbedForm,
  FormTab,
  required,
  ReferenceInput,
  SelectInput,
  BooleanInput,
  ArrayInput,
  SimpleFormIterator,
  email,
  FormDataConsumer,
  AutocompleteInput,
} from 'react-admin'
import FavoriteIcon from '@material-ui/icons/Favorite'
import { Typography, Box, Toolbar } from '@material-ui/core'
import EmailTemplateReferenceInput from './EmailTemplateReferenceInput'

const EmailCampaignsForm = (props) => {
  return (
    <TabbedForm {...props}>
      <FormTab label="main">
        <TextInput source="title" validate={[required()]} />
        <BooleanInput label="Is test" source="isTest" />
        <EmailTemplateReferenceInput />
        <TextInput source="sender" validate={[email('Invalid email')]} />
        <TextInput source="subject" validate={[]} />
        <ReferenceInput
          label="Media"
          source="media.id"
          reference="media"
          allowEmpty
          filterToQuery={(searchText) => ({ _q: { name: searchText } })}
        >
          <AutocompleteInput source="name" />
        </ReferenceInput>
        <BooleanInput
          label="Is to all"
          source="isToAll"
          options={{
            checkedIcon: <FavoriteIcon />,
          }}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            !formData.isToAll && (
              <>
                <ReferenceInput
                  label="Specialization"
                  source="specialization.id"
                  reference="specializations"
                >
                  <SelectInput optionText="title" />
                </ReferenceInput>
                <ArrayInput source="selectedEmails">
                  <SimpleFormIterator>
                    <TextInput validate={[email('Invalid email')]} />
                  </SimpleFormIterator>
                </ArrayInput>
              </>
            )
          }
        </FormDataConsumer>
      </FormTab>
    </TabbedForm>
  )
}

export default EmailCampaignsForm
