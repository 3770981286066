import React from 'react'
import {
  SimpleForm,
  TextInput,
  Edit,
  BooleanInput,
  required,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin'
import { TextEditor } from '../common/TextEditor'
import ImagesReferenceInput from '../common/ImagesReferenceInput'

const ProductsEdit = (props) => {
  const transform = (data) => {
    const newData = {}

    Object.keys(data).forEach((i) => {
      if (typeof data[i] === 'object' && data[i].id) {
        newData[i] = data[i].id
      } else {
        newData[i] = data[i]
      }
    })

    if (newData.id) {
      delete newData.id
    }
    return newData
  }

  return (
    <Edit {...props} transform={transform}>
      <SimpleForm>
        <TextInput source="title" validate={[required()]} />
        <TextInput source="identificator" />
        <TextInput source="externalLink" />
        <BooleanInput label="isVisibleInSlider" source="isVisibleInSlider" />
        <BooleanInput label="isAccessibleFromInternet" source="isAccessibleFromInternet" />
        <ImagesReferenceInput validate={[required()]} />
        <TextEditor source="content" {...props.rest} />
        <ReferenceInput
          label="Pdf file"
          source="pdfFile.id"
          reference="pdf-files"
          allowEmpty
          filterToQuery={(searchText) => ({ _q: { title: searchText } })}
          validate={[required()]}
        >
          <AutocompleteInput source="title" optionText="title" />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  )
}

export default ProductsEdit
