import React from 'react'
import { Create, useNotify, useRedirect } from 'react-admin'

const EmailTemplatesCreateHOC = (WrappedComponent) => {
  return (props) => {
    const transform = (data) => {
      const newData = {}

      Object.keys(data).forEach((i) => {
        if (typeof data[i] === 'object' && data[i].id) {
          newData[i] = data[i].id
        } else {
          newData[i] = data[i]
        }
      })

      if (newData.id) {
        delete newData.id
      }

      return newData
    }

    const notify = useNotify()
    const redirect = useRedirect()
    const onSuccess = ({ data }) => {
      notify('ra.notification.created', 'info', { smart_count: 1 })
      redirect('list', props.basePath, data.id, data)
    }

    return (
      <Create {...props} onSuccess={onSuccess} transform={transform}>
        <WrappedComponent />
      </Create>
    )
  }
}

export default EmailTemplatesCreateHOC
