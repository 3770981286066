import React, { useEffect, useState } from 'react'
import AddIcon from '@material-ui/icons/Add'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import { useDataProvider, Loading } from 'react-admin'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'

const useStyles = makeStyles((theme) => ({}))

const MediaItem = ({ media, onClick }) => {
  return (
    <ListItem button onClick={onClick}>
      <ListItemText primary={media.name} />
    </ListItem>
  )
}

const AddMediaButton = ({ group, addMediaItem, deletedMediaItems, newAddedMediaItems }) => {
  const classes = useStyles()
  const dataProvider = useDataProvider()

  const [showDialog, setShowDialog] = useState(false)
  const [media, setMedia] = useState([])

  const handleClick = () => {
    setShowDialog(true)
  }

  const handleCloseClick = () => {
    setShowDialog(false)
  }

  useEffect(async () => {
    if (!showDialog) {
      return
    }

    const { data: media } = await dataProvider.getList('media', {
      pagination: { page: 1, perPage: 999999 },
      filter: { mediaGroup: null },
      sort: { field: 'id', order: 'ASC' },
    })

    let alreadyAddedMediaIds = group.media.map((i) => i.id) || []
    let newAddedMediaItemsFromOtherGroupsIds = newAddedMediaItems.map((i) => i.id)

    setMedia(
      media
        .filter((i) => {
          return (
            !alreadyAddedMediaIds.includes(i.id) &&
            !newAddedMediaItemsFromOtherGroupsIds.includes(i.id)
          )
        })
        .concat(deletedMediaItems)
    )
  }, [showDialog])

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        startIcon={<AddIcon />}
        onClick={handleClick}
      >
        Media
      </Button>
      <Dialog fullWidth open={showDialog} onClose={handleCloseClick} aria-label={'Add media'}>
        <DialogTitle>Add media</DialogTitle>

        <React.Fragment>
          <DialogContent>
            <List component="nav" aria-label="main mailbox folders">
              {media.length &&
                media.map((item) => {
                  return (
                    <React.Fragment key={item.id}>
                      <MediaItem
                        media={item}
                        onClick={() => {
                          addMediaItem(item)
                          handleCloseClick()
                        }}
                      />
                      <Divider variant="fullWidth" component="li" />
                    </React.Fragment>
                  )
                })}
            </List>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseClick} color={'secondary'}>
              {'cancel'}
            </Button>
          </DialogActions>
        </React.Fragment>
      </Dialog>
    </>
  )
}

export default AddMediaButton
