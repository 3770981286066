import React, { useEffect, useState } from 'react'
import { useDataProvider, Loading } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import groupByDay from './groupByDay'
import DateFnsUtils from '@date-io/date-fns'
import Grid from '@material-ui/core/Grid'
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import TimelineLoaded from './TimelineLoaded'

const useStyles = makeStyles((theme) => ({
  root: {
    width: 600,
    margin: 'auto',
  },
  day: {
    marginBottom: '1em',
  },
}))

const Timeline = ({ record }) => {
  const classes = useStyles()
  const dataProvider = useDataProvider()

  const [loading, setLoading] = useState(true)

  const initialDateTo = new Date()
  const initialDateFrom = new Date()
  initialDateFrom.setMonth(initialDateTo.getMonth() - 1)

  const [dateTo, setDateTo] = useState(initialDateTo)
  const [dateFrom, setDateFrom] = useState(initialDateFrom)

  const [data, setData] = useState(null)

  useEffect(async () => {
    setLoading(true)

    const commentAddActions = dataProvider.getList('comment-add-actions', {
      pagination: { page: 1, perPage: 999999 },
      filter: {
        user: record.id,
        _dateRange: {
          createdAt: [dateFrom.toISOString().slice(0, 10), dateTo.toISOString().slice(0, 10)],
        },
      },
      sort: { field: 'id', order: 'ASC' },
    })

    const emailLinkRegistrationActions = dataProvider.getList('email-link-registration-actions', {
      pagination: { page: 1, perPage: 999999 },
      filter: {
        user: record.id,
        _dateRange: {
          createdAt: [dateFrom.toISOString().slice(0, 10), dateTo.toISOString().slice(0, 10)],
        },
      },
      sort: { field: 'id', order: 'ASC' },
    })

    const loginActions = dataProvider.getList('login-actions', {
      pagination: { page: 1, perPage: 999999 },
      filter: {
        user: record.id,
        _dateRange: {
          createdAt: [dateFrom.toISOString().slice(0, 10), dateTo.toISOString().slice(0, 10)],
        },
      },
      sort: { field: 'id', order: 'ASC' },
    })

    const mediaLikeActions = dataProvider.getList('media-like-actions', {
      pagination: { page: 1, perPage: 999999 },
      filter: {
        user: record.id,
        _dateRange: {
          createdAt: [dateFrom.toISOString().slice(0, 10), dateTo.toISOString().slice(0, 10)],
        },
      },
      sort: { field: 'id', order: 'ASC' },
    })

    const mediaVisitActions = dataProvider.getList('media-visit-actions', {
      pagination: { page: 1, perPage: 999999 },
      filter: {
        user: record.id,
        _dateRange: {
          createdAt: [dateFrom.toISOString().slice(0, 10), dateTo.toISOString().slice(0, 10)],
        },
      },
      sort: { field: 'id', order: 'ASC' },
    })

    const registerActions = dataProvider.getList('register-actions', {
      pagination: { page: 1, perPage: 999999 },
      filter: {
        user: record.id,
        _dateRange: {
          createdAt: [dateFrom.toISOString().slice(0, 10), dateTo.toISOString().slice(0, 10)],
        },
      },
      sort: { field: 'id', order: 'ASC' },
    })

    const surveyFinishActions = dataProvider.getList('survey-finish-actions', {
      pagination: { page: 1, perPage: 999999 },
      filter: {
        user: record.id,
        _dateRange: {
          createdAt: [dateFrom.toISOString().slice(0, 10), dateTo.toISOString().slice(0, 10)],
        },
      },
      sort: { field: 'id', order: 'ASC' },
    })

    const userVerificationActions = dataProvider.getList('user-verification-actions', {
      pagination: { page: 1, perPage: 999999 },
      filter: {
        user: record.id,
        _dateRange: {
          createdAt: [dateFrom.toISOString().slice(0, 10), dateTo.toISOString().slice(0, 10)],
        },
      },
      sort: { field: 'id', order: 'ASC' },
    })

    const certificateFetchActions = dataProvider.getList('certificate-fetch-actions', {
      pagination: { page: 1, perPage: 999999 },
      filter: {
        user: record.id,
        _dateRange: {
          createdAt: [dateFrom.toISOString().slice(0, 10), dateTo.toISOString().slice(0, 10)],
        },
      },
      sort: { field: 'id', order: 'ASC' },
    })

    const r = await Promise.all([
      commentAddActions,
      emailLinkRegistrationActions,
      loginActions,
      mediaLikeActions,
      mediaVisitActions,
      registerActions,
      surveyFinishActions,
      userVerificationActions,
      certificateFetchActions,
    ])

    const data = groupByDay(
      r
        .map((i) => {
          return i.data
        })
        .flat()
    )

    setData(data)

    setLoading(false)
  }, [dateFrom, dateTo])

  return (
    <div className={classes.root}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container justify="start">
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="yyyy-MM-dd"
            margin="normal"
            id="date-picker-inline1"
            label="Date from"
            value={dateFrom}
            onChange={setDateFrom}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="yyyy-MM-dd"
            margin="normal"
            id="date-picker-inline2"
            label="Date to"
            value={dateTo}
            onChange={setDateTo}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            className={classes.controlsMargin}
          />
        </Grid>
      </MuiPickersUtilsProvider>
      {loading ? <Loading /> : <TimelineLoaded data={data} classes={classes} />}
    </div>
  )
}

export default Timeline
