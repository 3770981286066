import React from 'react'
import { Create, useNotify, useRedirect } from 'react-admin'

const ModulesCreateHOC = (WrappedComponent) => {
  return (props) => {
    const transform = (data) => {
      const newData = {}

      Object.keys(data).forEach((i) => {
        if (i === 'mediaGroups') {
          for (const [key, value] of Object.entries(data[i])) {
            if (!data[i][key]) {
              delete data[i][key]
              continue
            }
            let newItem = {
              ...data[i][key],
              media: data[i][key].media.map((item) => item.id),
              surveys: data[i][key].surveys.map((item) => item.id),
            }
            data[i][key] = newItem
          }

          newData[i] = data[i]
        } else if (typeof data[i] === 'object' && data[i].id) {
          newData[i] = data[i].id
        } else {
          newData[i] = data[i]
        }
      })

      if (newData.id) {
        delete newData.id
      }

      return newData
    }

    const notify = useNotify()
    const redirect = useRedirect()
    const onSuccess = ({ data }) => {
      notify('ra.notification.created', 'info', { smart_count: 1 })
      redirect('list', props.basePath, data.id, data)
    }

    return (
      <Create {...props} onSuccess={onSuccess} transform={transform} mutationMode="pessimistic">
        <WrappedComponent />
      </Create>
    )
  }
}

export default ModulesCreateHOC
