import React from 'react'
import {
  SimpleForm,
  TextInput,
  Edit,
  SelectInput,
  required,
  DateInput,
  ReferenceInput,
} from 'react-admin'

const MarketingEntriesEdit = (props) => {
  const transform = (data) => {
    const newData = {}

    Object.keys(data).forEach((i) => {
      if (Array.isArray(data[i])) {
        newData[i] = data[i].map((item) => (item.id ? item.id : item))
      } else if (typeof data[i] === 'object' && data[i].id) {
        newData[i] = data[i].id
      } else {
        newData[i] = data[i]
      }
    })

    if (newData.id) {
      delete newData.id
    }

    return newData
  }

  return (
    <Edit {...props} transform={transform}>
      <SimpleForm>
        <TextInput fullWidth={true} source="title" validate={[required()]} />
        <ReferenceInput label="Media" source="media.id" reference="media" validate={[required()]}>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <DateInput source="entryDate" validate={[required()]} />
      </SimpleForm>
    </Edit>
  )
}

export default MarketingEntriesEdit
