import {
  ReferenceArrayInput,
  SelectArrayInput,
  required,
  AutocompleteArrayInput,
} from 'react-admin'
import { useState, useCallback } from 'react'
import { useFormState } from 'react-final-form'
import { makeStyles } from '@material-ui/core/styles'
import MediaTagQuickCreateButton from './MediaTagQuickCreateButton'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
})

const spySubscription = { values: true }

const MediaTagReferenceInput = (props) => {
  const classes = useStyles()
  const [version, setVersion] = useState(0)
  const { values } = useFormState({ subscription: spySubscription })
  const handleChange = useCallback(() => setVersion(version + 1), [version])

  return (
    <div className={classes.root}>
      <ReferenceArrayInput
        key={version}
        source="mediaTags"
        reference="media-tags"
        format={(i) => i && i.map((m) => (m.id ? m.id : m))}
        filterToQuery={(searchText) => ({ _q: { title: searchText } })}
      >
        <AutocompleteArrayInput source="title" optionText="title" />
      </ReferenceArrayInput>

      <MediaTagQuickCreateButton onChange={handleChange} />
    </div>
  )
}

export default MediaTagReferenceInput
