import * as React from 'react'
import {
  Show,
  SimpleShowLayout,
  TextField,
  RefreshButton,
  TopToolbar,
  useRecordContext,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { servicesHost } from '../../utils/dataprovider'

const useStyles = makeStyles({
  iframeContainer: {
    height: '50vh',
  },
  iframe: {
    width: '100%',
    height: '100%',
  },
})

const PdfPreview = (props) => {
  const classes = useStyles()
  const record = useRecordContext(props)

  return (
    <div className={classes.iframeContainer}>
      <iframe
        className={classes.iframe}
        src={`${servicesHost.host}/uploads/files/${record.file.name}`}
      />
    </div>
  )
}

const PdfFilesShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="title" />
      <TextField source="identificator" />
      <PdfPreview />
    </SimpleShowLayout>
  </Show>
)

export default PdfFilesShow
