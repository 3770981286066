// import SurveysCreate from './SurveysCreate'
import SurveysForm from './SurveysForm'
import SurveysCreateHOC from './SurveysCreateHOC'
import SurveysEditHOC from './SurveysEditHOC'
import SurveysList from './SurveysList'

const SurveysCreate = SurveysCreateHOC(SurveysForm)
const SurveysEdit = SurveysEditHOC(SurveysForm)

export default {
  create: SurveysCreate,
  edit: SurveysEdit,
  list: SurveysList,
}
