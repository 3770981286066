import * as React from 'react'
import { Admin, Resource, ListGuesser, EditGuesser, CreateGuesser } from 'react-admin'
import dataProvider from './utils/dataprovider'
import LoginPage from './LoginPage'

import media from './pages/media'
import modules from './pages/modules'
import surveys from './pages/surveys'
import exams from './pages/exams'
import images from './pages/images'
import settings from './pages/settings'
import mediaAuthors from './pages/mediaAuthors'
import pages from './pages/pages'
import products from './pages/products'
import users from './pages/users'
import specializations from './pages/specializations'
import comments from './pages/comments'
import emailTemplates from './pages/emailTemplates'
import emailCampaigns from './pages/emailCampaigns'
import surveyFillEntries from './pages/surveyFillEntries'
import marketingEntries from './pages/marketingEntries'
import agreements from './pages/agreements'
import notificationCampaigns from './pages/notificationCampaigns'
import pdfFiles from './pages/pdfFiles'

import PostIcon from '@material-ui/icons/Book'
import UserIcon from '@material-ui/icons/Group'
import ViewModuleIcon from '@material-ui/icons/ViewModule'
import ImageIcon from '@material-ui/icons/Image'
import FormatListNumberedRtlIcon from '@material-ui/icons/FormatListNumberedRtl'
import SettingsIcon from '@material-ui/icons/Settings'
import WorkIcon from '@material-ui/icons/Work'
import MessageIcon from '@material-ui/icons/Message'
import EmailIcon from '@material-ui/icons/Email'
import AllInboxIcon from '@material-ui/icons/AllInbox'
import DoneAllIcon from '@material-ui/icons/DoneAll'
import TurnedInNotIcon from '@material-ui/icons/TurnedInNot'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'

import Dashboard from './Dashboard'
import authProvider from './utils/authProvider'

const App = () => (
  <Admin
    loginPage={LoginPage}
    dataProvider={dataProvider}
    dashboard={Dashboard}
    authProvider={authProvider}
  >
    {/* <Resource name="posts" list={PostList} edit={PostEdit} create={PostCreate} icon={PostIcon} /> */}
    <Resource name="users" icon={UserIcon} {...users} />
    <Resource name="modules" list={ListGuesser} {...modules} />
    <Resource name="media" {...media} icon={ViewModuleIcon} />
    <Resource name="surveys" list={ListGuesser} icon={FormatListNumberedRtlIcon} {...surveys} />
    <Resource name="exams" list={ListGuesser} icon={FormatListNumberedRtlIcon} {...exams} />
    <Resource name="survey-fill-entries" {...surveyFillEntries} icon={DoneAllIcon} />
    <Resource name="images" list={ListGuesser} {...images} icon={ImageIcon} />
    <Resource name="settings" {...settings} icon={SettingsIcon} />
    <Resource name="pages" {...pages} />
    <Resource name="products" icon={TurnedInNotIcon} {...products} />
    <Resource name="specializations" {...specializations} icon={WorkIcon} />
    <Resource name="media-authors" {...mediaAuthors} />
    <Resource name="email-templates" {...emailTemplates} icon={EmailIcon} />
    <Resource name="email-campaigns" {...emailCampaigns} icon={AllInboxIcon} />
    <Resource name="notification-campaigns" {...notificationCampaigns} />
    <Resource name="marketing-entries" {...marketingEntries} />
    <Resource name="agreements" {...agreements} />
    <Resource name="pdf-files" icon={PictureAsPdfIcon} {...pdfFiles} />
    <Resource name="video-media-sources" />
    <Resource name="edetailing-media-sources" />
    <Resource name="presentation-media-sources" />
    <Resource name="pdf-media-sources" />
    <Resource name="podcast-media-sources" />
    <Resource name="media-groups" />
    <Resource name="user-groups" />
    <Resource name="comments" {...comments} icon={MessageIcon} />

    <Resource name="comment-add-actions" />
    <Resource name="email-link-registration-actions" />
    <Resource name="email-link-visit-actions" />
    <Resource name="email-open-actions" />
    <Resource name="login-actions" />
    <Resource name="media-like-actions" />
    <Resource name="media-time-spent-actions" />
    <Resource name="media-visit-actions" />
    <Resource name="register-actions" />
    <Resource name="user-verification-actions" />
    <Resource name="survey-finish-actions" />
    <Resource name="certificate-fetch-actions" />
    <Resource name="media-tags" />

    <Resource name="certificates" />
  </Admin>
)

export default App
