import {
  TextInput,
  BooleanInput,
  NumberInput,
  TabbedForm,
  FormTab,
  required,
  ReferenceArrayInput,
  SelectArrayInput,
} from 'react-admin'
import ImagesReferenceInput from '../common/ImagesReferenceInput'
import MediaGroupsList from './MediaGroupsList'

const ModulesForm = (props) => {
  return (
    <TabbedForm {...props}>
      <FormTab label="main">
        <TextInput source="name" validate={[required()]} />
        <TextInput source="shortName" validate={[required()]} />
        <BooleanInput source="isEnabled" />
        <NumberInput source="position" min={0} validate={[required()]} />
        <ImagesReferenceInput
          source="icon.id"
          reference="images"
          fieldName="icon"
          label="icon"
          isRequired={false}
        />
        <MediaGroupsList source={'mediaGroups'} />
        <TextInput multiline source="description" fullwidth />
      </FormTab>
      <FormTab label="locks">
        <BooleanInput source="isLocked" />
      </FormTab>
    </TabbedForm>
  )
}

export default ModulesForm
