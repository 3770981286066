import { TextField, DateField, Datagrid, List } from 'react-admin'

const EmailTemplatesList = (props) => (
  <List {...props} filter={{ isArchived: false }}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="title" />
      <TextField source="uuid" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  </List>
)

export default EmailTemplatesList
