import React, { useState } from 'react'
import Drawer from '@material-ui/core/Drawer'
import { makeStyles } from '@material-ui/core/styles'

import IconImageEye from '@material-ui/icons/RemoveRedEye'
import IconKeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import Grid from '@material-ui/core/Grid'
import { Button, SimpleShowLayout, TextField, useGetOne, ImageField } from 'react-admin'
import { servicesHost } from '../../utils/dataprovider'

const useStyles = makeStyles({
  field: {
    '& span': {
      display: 'inline-block',
      maxWidth: '20em',
    },
  },
  drawerPaper: {
    width: '50%',
  },
  iframeContainer: {
    height: '50vh',
  },
  img: {
    maxWidth: '100%',
  },
})

const ImagesQuickPreviewButton = ({ id }) => {
  const [showPanel, setShowPanel] = useState(false)
  const classes = useStyles()
  const { data } = useGetOne('images', id)

  const handleClick = () => {
    setShowPanel(true)
  }

  const handleCloseClick = () => {
    setShowPanel(false)
  }

  return (
    <>
      <Button onClick={handleClick} label="ra.action.show">
        <IconImageEye />
      </Button>
      <Drawer
        anchor="right"
        open={showPanel}
        onClose={handleCloseClick}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div>
          <Button label="Close" onClick={handleCloseClick}>
            <IconKeyboardArrowRight />
          </Button>
        </div>
        <SimpleShowLayout record={data} basePath="/images" resource="images">
          <TextField source="id" />
          {data && (
            <img src={`${servicesHost.host}/uploads/images/${data.name}`} className={classes.img} />
          )}
        </SimpleShowLayout>
      </Drawer>
    </>
  )
}

export default ImagesQuickPreviewButton
