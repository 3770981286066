import { Edit, Toolbar, useUpdate, SaveButton, useRedirect } from 'react-admin'
import Button from '@material-ui/core/Button'
import SendIcon from '@material-ui/icons/Send'
import { EMAIL_CAMPAIGN_STATUS_IN_PROGRESS, EMAIL_CAMPAIGN_STATUS_DRAFT } from '../../constants'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}))

const EditToolbar = (props) => {
  const transform = (data) => {
    const newData = {}

    Object.keys(data).forEach((i) => {
      if (Array.isArray(data[i])) {
        newData[i] = data[i].map((item) => (item.id ? item.id : item))
      } else if (typeof data[i] === 'object' && data[i].id) {
        newData[i] = data[i].id
      } else {
        newData[i] = data[i]
      }
    })

    if (newData.id) {
      delete newData.id
    }

    return newData
  }

  const diff = { ...transform(props.record), status: EMAIL_CAMPAIGN_STATUS_IN_PROGRESS }
  const [update, { loading, error }] = useUpdate(
    props.resource,
    props.record.id,
    diff,
    props.record
  )

  const redirect = useRedirect()

  const classes = useStyles()

  return (
    <Toolbar {...props}>
      <SaveButton label={'save and exit'} disabled={props.pristine} className={classes.button} />
      <SaveButton
        label="only save"
        redirect={false}
        submitOnEnter={false}
        variant="text"
        disabled={props.pristine}
        className={classes.button}
      />
      <Button
        variant="contained"
        color="primary"
        endIcon={<SendIcon />}
        onClick={async () => {
          await update()
          redirect('show', props.basePath, props.record.id)
        }}
        disabled={!props.pristine || props.record.status !== EMAIL_CAMPAIGN_STATUS_DRAFT}
        className={classes.button}
      >
        start
      </Button>
    </Toolbar>
  )
}

const EmailCampaignsEditHOC = (WrappedComponent) => {
  return (props) => {
    const transform = (data) => {
      const newData = {}

      Object.keys(data).forEach((i) => {
        if (Array.isArray(data[i])) {
          newData[i] = data[i].map((item) => (item.id ? item.id : item))
        } else if (typeof data[i] === 'object' && data[i].id) {
          newData[i] = data[i].id
        } else {
          newData[i] = data[i]
        }
      })

      if (newData.id) {
        delete newData.id
      }

      return newData
    }

    return (
      <Edit {...props} transform={transform}>
        <WrappedComponent toolbar={<EditToolbar />} />
      </Edit>
    )
  }
}

export default EmailCampaignsEditHOC
