import React, { useEffect, useState } from 'react'
import AddIcon from '@material-ui/icons/Add'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import { useDataProvider, Loading } from 'react-admin'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'

const useStyles = makeStyles((theme) => ({}))

const SurveyItem = ({ survey, onClick }) => {
  return (
    <ListItem button onClick={onClick}>
      <ListItemText primary={survey.title} />
    </ListItem>
  )
}

const AddSurveyButton = ({ group, addSurveyItem, deletedSurveyItems, newAddedSurveyItems }) => {
  const classes = useStyles()
  const dataProvider = useDataProvider()

  const [showDialog, setShowDialog] = useState(false)
  const [surveys, setSurveys] = useState([])

  const handleClick = () => {
    setShowDialog(true)
  }

  const handleCloseClick = () => {
    setShowDialog(false)
  }

  useEffect(async () => {
    if (!showDialog) {
      return
    }

    const { data: media } = await dataProvider.getList('surveys', {
      pagination: { page: 1, perPage: 999999 },
      filter: { mediaGroup: null },
      sort: { field: 'id', order: 'ASC' },
    })

    let alreadyAddedSurveysIds = group.surveys.map((i) => i.id) || []
    let newAddedSurveyItemsFromOtherGroupsIds = newAddedSurveyItems.map((i) => i.id)

    setSurveys(
      media
        .filter((i) => {
          return (
            !alreadyAddedSurveysIds.includes(i.id) &&
            !newAddedSurveyItemsFromOtherGroupsIds.includes(i.id)
          )
        })
        .concat(deletedSurveyItems)
    )
  }, [showDialog])

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        startIcon={<AddIcon />}
        onClick={handleClick}
      >
        Survey
      </Button>
      <Dialog fullWidth open={showDialog} onClose={handleCloseClick} aria-label={'Add survey'}>
        <DialogTitle>Add survey</DialogTitle>

        <React.Fragment>
          <DialogContent>
            <List component="nav" aria-label="main mailbox folders">
              {surveys.length &&
                surveys.map((item) => {
                  return (
                    <React.Fragment key={item.id}>
                      <SurveyItem
                        survey={item}
                        onClick={() => {
                          addSurveyItem(item)
                          handleCloseClick()
                        }}
                      />
                      <Divider variant="fullWidth" component="li" />
                    </React.Fragment>
                  )
                })}
            </List>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseClick} color={'secondary'}>
              {'cancel'}
            </Button>
          </DialogActions>
        </React.Fragment>
      </Dialog>
    </>
  )
}

export default AddSurveyButton
