import React from 'react'
import {
  SimpleForm,
  TextInput,
  Create,
  BooleanInput,
  useNotify,
  useRedirect,
  required,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin'
import ImagesReferenceInput from '../common/ImagesReferenceInput'
import { TextEditor } from '../common/TextEditor'

const ProductsCreate = (props) => {
  const notify = useNotify()
  const redirect = useRedirect()
  const onSuccess = ({ data }) => {
    notify('ra.notification.created', 'info', { smart_count: 1 })
    redirect('list', props.basePath, data.id, data)
  }

  const transform = (data) => {
    const newData = {}

    Object.keys(data).forEach((i) => {
      if (typeof data[i] === 'object' && data[i].id) {
        newData[i] = data[i].id
      } else {
        newData[i] = data[i]
      }
    })

    if (newData.id) {
      delete newData.id
    }

    return newData
  }

  return (
    <Create {...props} onSuccess={onSuccess} transform={transform}>
      <SimpleForm>
        <TextInput source="title" validate={[required()]} />
        <TextInput source="identificator" />
        <TextInput source="externalLink" />
        <BooleanInput label="isVisibleInSlider" source="isVisibleInSlider" />
        <BooleanInput label="isAccessibleFromInternet" source="isAccessibleFromInternet" />
        <ImagesReferenceInput validate={[required()]} />
        <TextEditor {...props.rest} source="content" />
        <ReferenceInput
          label="Pdf file"
          source="pdfFile.id"
          reference="pdf-files"
          allowEmpty
          filterToQuery={(searchText) => ({ _q: { title: searchText } })}
        >
          <AutocompleteInput source="title" optionText="title" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  )
}

export default ProductsCreate
