import {
  TextField,
  ReferenceField,
  Datagrid,
  List,
  ChipField,
  DateField,
  BooleanField,
  downloadCSV,
} from 'react-admin'
import jsonExport from 'jsonexport/dist'

const exporter = (surveyFillEntries) => {
  const entriesForExport = surveyFillEntries.map((fillEntry) => {
    const { survey, surveyFillEntryItems, user, ...entriesForExport } = fillEntry

    entriesForExport['survey_id'] = survey.id
    entriesForExport['user_id'] = user.id

    return entriesForExport
  })
  jsonExport(
    entriesForExport,
    {
      headers: [], // order fields in the export
    },
    (err, csv) => {
      downloadCSV(csv, 'survey_fill_entries') // download as 'posts.csv` file
    }
  )
}

const SurveyFillEntriesList = (props) => (
  <List {...props} exporter={exporter}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <ReferenceField label="User" source="user.id" reference="users" link="show">
        <ChipField source="email" />
      </ReferenceField>
      <ReferenceField label="Survey" source="survey.id" reference="surveys" link="edit">
        <ChipField source="title" />
      </ReferenceField>
      <BooleanField source="isFinished" />
      <DateField source="createdAt" />
    </Datagrid>
  </List>
)

export default SurveyFillEntriesList
