import { ReferenceInput, SelectInput, required } from 'react-admin'
import { useState, useCallback } from 'react'
import { useFormState } from 'react-final-form'
import { makeStyles } from '@material-ui/core/styles'
import EmailTemplateQuickPreviewButton from './EmailTemplateQuickPreviewButton'
import EmailTemplateQuickCreateButton from './EmailTemplateQuickCreateButton'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
})

const spySubscription = { values: true }

const EmailTemplateReferenceInput = (props) => {
  const classes = useStyles()
  const [version, setVersion] = useState(0)
  const { values } = useFormState({ subscription: spySubscription })
  const handleChange = useCallback(() => setVersion(version + 1), [version])

  return (
    <div className={classes.root}>
      <ReferenceInput
        key={version}
        {...props}
        source="emailTemplate.id"
        reference="email-templates"
        allowEmpty
        filter={{ isArchived: false }}
        validate={required()}
      >
        <SelectInput optionText="title" />
      </ReferenceInput>

      <EmailTemplateQuickCreateButton onChange={handleChange} />
      {!!values.emailTemplate && (
        <EmailTemplateQuickPreviewButton
          id={values.emailTemplate.id ? values.emailTemplate.id : values.emailTemplate}
        />
      )}
    </div>
  )
}

export default EmailTemplateReferenceInput
