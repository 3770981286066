import React from 'react'
import { SimpleForm, TextInput, Edit, SelectInput, required } from 'react-admin'
import { SETTINGS_TYPE } from '../../constants'

const typeChoices = SETTINGS_TYPE.map((item, index) => ({
  id: index,
  name: item,
}))

const SettingsEdit = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <SelectInput disabled source="type" validate={[required()]} choices={typeChoices} />
        <TextInput fullWidth={true} source="value" />
      </SimpleForm>
    </Edit>
  )
}

export default SettingsEdit
