import simpleRestProvider from 'ra-data-simple-rest'
import { fetchUtils } from 'react-admin'

export const servicesHost = {
  // host: 'http://amp-academy-backend',
  // host: 'https://akademiamistrzowpsychiatrii.pl',
  host: 'https://akademiamistrzowpsychiatrii.pl',
  path: 'api',
}

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' })
  }

  const { token } = JSON.parse(localStorage.getItem('auth'))

  options.user = {
    authenticated: true,
    token: `Bearer ${token}`,
  }
  return fetchUtils.fetchJson(url, options)
}

const dataProvider = simpleRestProvider(
  `${servicesHost.host}/${servicesHost.path}`,
  httpClient,
  'X-Total-Count'
)

export default {
  ...dataProvider,
  create: (resource, params) => {
    if (['images', 'pdf-media-sources', 'pdf-files', 'podcast-media-sources'].includes(resource)) {
      let formData = new FormData()

      for (let [key, value] of Object.entries(params.data)) {
        if (value.rawFile) {
          formData.append(key, value.rawFile)
        } else if (typeof value === 'object') {
          for (let [key1, value1] of Object.entries(value)) {
            if (value1.rawFile) {
              formData.append(`${key}[${key1}]`, value1.rawFile)
            } else {
              if (typeof value1 === 'object') {
                value1 = JSON.stringify(value1)
              }
              formData.append(`${key}[${key1}]`, value1)
            }
          }
        } else {
          if (typeof value === 'object') {
            value = JSON.stringify(value)
          }
          formData.append(key, value)
        }
      }

      return httpClient(`${servicesHost.host}/${servicesHost.path}/${resource}`, {
        method: 'POST',
        body: formData,
      }).then(({ json }) => ({
        data: { ...params.data, id: json.id },
      }))
    } else if (['edetailing-media-sources', 'email-templates'].includes(resource)) {
      let formData = new FormData()

      for (let [key, value] of Object.entries(params.data)) {
        if (Array.isArray(value)) {
          value.forEach((element, index) => {
            if (element.rawFile) {
              formData.append(element.rawFile.path, element.rawFile)
            }
          })
        } else {
          formData.append(key, value)
        }
      }

      return httpClient(`${servicesHost.host}/${servicesHost.path}/${resource}`, {
        method: 'POST',
        body: formData,
      }).then(({ json }) => ({
        data: { ...params.data, id: json.id },
      }))
    } else if (['presentation-media-sources'].includes(resource)) {
      let formData = new FormData()

      for (let [key, value] of Object.entries(params.data)) {
        if (typeof value === 'object') {
          for (let [key1, value1] of Object.entries(value)) {
            formData.append(
              `${key}[${key1}][${Object.keys(value1)[0]}]`,
              value1[Object.keys(value1)[0]].rawFile
            )
          }
        } else {
          formData.append(key, value)
        }
      }

      return httpClient(`${servicesHost.host}/${servicesHost.path}/${resource}`, {
        method: 'POST',
        body: formData,
      }).then(({ json }) => ({
        data: { ...params.data, id: json.id },
      }))
    } else {
      // fallback to the default implementation
      return dataProvider.create(resource, params)
    }
  },
  // update: (resource, params) => {
  //   if (resource === 'media') {
  //     let formData = new FormData()

  //     for (let [key, value] of Object.entries(params.data)) {
  //       if (value.rawFile) {
  //         formData.append(key, value.rawFile)
  //       } else {
  //         if (typeof value === 'object') {
  //           value = JSON.stringify(value)
  //         }
  //         formData.append(key, value)
  //       }
  //     }

  //     return httpClient(`${servicesHost.host}/${servicesHost.path}/${resource}?_method=PUT`, {
  //       method: 'POST',
  //       body: formData,
  //     }).then(({ json }) => ({
  //       data: { ...params.data, id: json.id },
  //     }))
  //   } else {
  //     // fallback to the default implementation
  //     return dataProvider.update(resource, params)
  //   }
  // },
  pdfExample: (id) => {
    return httpClient(`${servicesHost.host}/${servicesHost.path}/certificates/${id}/pdf-example`, {
      method: 'get',
    }).then(({ json }) => ({
      data: json,
    }))
  },
}
