import { ReferenceInput, SelectInput, required } from 'react-admin'
import { useState, useCallback } from 'react'
import { useFormState } from 'react-final-form'
import { makeStyles } from '@material-ui/core/styles'
import { servicesHost } from '../../utils/dataprovider'
import PodcastMediaSourceQuickPreviewButton from './PodcastMediaSourceQuickPreviewButton'
import PodcastMediaSourceQuickCreateButton from './PodcastMediaSourceQuickCreateButton'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
})

const spySubscription = { values: true }

const PodcastMediaSourceReferenceInput = (props) => {
  const classes = useStyles()
  const [version, setVersion] = useState(0)
  const { values } = useFormState({ subscription: spySubscription })
  const handleChange = useCallback(() => setVersion(version + 1), [version])

  return (
    <div className={classes.root}>
      <ReferenceInput
        key={version}
        {...props}
        source="podcastSource.id"
        reference="podcast-media-sources"
        allowEmpty
        validate={required()}
      >
        <SelectInput optionText="title" />
      </ReferenceInput>

      <PodcastMediaSourceQuickCreateButton onChange={handleChange} />
      {!!values.podcastSource && (
        <PodcastMediaSourceQuickPreviewButton
          id={values.podcastSource.id ? values.podcastSource.id : values.podcastSource}
        />
      )}
    </div>
  )
}

export default PodcastMediaSourceReferenceInput
