import React, { useEffect, useState } from 'react'
import { useDataProvider, Loading } from 'react-admin'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'

import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Checkbox from '@material-ui/core/Checkbox'
import Paper from '@material-ui/core/Paper'

import Bar from './Bar'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
  },
  paper: {
    width: 200,
    height: 230,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}))

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1)
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1)
}

const UsersBySpecialization = (props) => {
  const dataProvider = useDataProvider()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])

  const initialDateTo = new Date()
  const initialDateFrom = new Date()
  initialDateFrom.setMonth(initialDateTo.getMonth() - 1)

  const [dateTo, setDateTo] = useState(initialDateTo)
  const [dateFrom, setDateFrom] = useState(initialDateFrom)

  const classes = useStyles()
  const [checked, setChecked] = useState([])
  const [left, setLeft] = useState([])
  const [right, setRight] = useState([])

  const leftChecked = intersection(checked, left)
  const rightChecked = intersection(checked, right)

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
  }

  const handleAllRight = () => {
    setRight(right.concat(left))
    setLeft([])
  }

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked))
    setLeft(not(left, leftChecked))
    setChecked(not(checked, leftChecked))
  }

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked))
    setRight(not(right, rightChecked))
    setChecked(not(checked, rightChecked))
  }

  const handleAllLeft = () => {
    setLeft(left.concat(right))
    setRight([])
  }

  useEffect(async () => {
    const { data: specializations } = await dataProvider.getList('specializations', {
      pagination: { page: 1, perPage: 999999 },
      filter: {},
      sort: { field: 'id', order: 'ASC' },
    })

    setRight(specializations)
  }, [])

  useEffect(async () => {
    await fetchData()
  }, [left, right, dateTo, dateFrom])

  const fetchData = async () => {
    setLoading(true)

    const specializationIds = []

    if (!right.length) {
      setData([])
    }

    for (const specialization of right) {
      specializationIds.push(specialization.id)
    }

    if (!specializationIds.length) {
      setLoading(false)
      return
    }

    let result = new Map()

    let filter = {
      _dateRange: {
        createdAt: [dateFrom.toISOString().slice(0, 10), dateTo.toISOString().slice(0, 10)],
      },
      isActive: true,
    }

    const { data: users } = await dataProvider.getList('users', {
      pagination: { page: 1, perPage: 999999 },
      filter: { ...filter, specialization: specializationIds },
      sort: { field: 'id', order: 'ASC' },
    })

    parseActions(users, 'Lekarz', result)

    setData(Array.from(result.values()))

    setLoading(false)
  }

  const parseActions = (users, label, parsed) => {
    const result = parsed || new Map()

    for (const item of users) {
      let specializationId = 'null'
      let specializationName = 'null'
      if (item.specialization) {
        specializationId = item.specialization.id
        specializationName = item.specialization.title
      }

      if (!result.has(specializationId)) {
        let obj = {}
        obj.specialization = specializationName
        obj[label] = 1
        result.set(specializationId, obj)
      } else {
        let obj = { ...result.get(specializationId) }
        obj[label] = result.get(specializationId)[label] ? ++result.get(specializationId)[label] : 1
        result.set(specializationId, obj)
      }
    }

    return result
  }

  const customList = (items) => (
    <Paper className={classes.paper}>
      <List dense component="div" role="list">
        {items.map((value) => {
          const labelId = `transfer-list-item-${value.id}-label`

          return (
            <ListItem key={value.id} role="listitem" button onClick={handleToggle(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${value.title}`} />
            </ListItem>
          )
        })}
        <ListItem />
      </List>
    </Paper>
  )

  return (
    <>
      <Grid container spacing={2} justify="start" alignItems="center" className={classes.root}>
        <Grid item>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="yyyy-MM-dd"
              margin="normal"
              id="date-picker-inline1"
              label="Date from"
              value={dateFrom}
              onChange={setDateFrom}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="yyyy-MM-dd"
              margin="normal"
              id="date-picker-inline2"
              label="Date to"
              value={dateTo}
              onChange={setDateTo}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              className={classes.controlsMargin}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item>{customList(left)}</Grid>
        <Grid item>
          <Grid container direction="column" alignItems="center">
            <Button
              variant="outlined"
              size="small"
              className={classes.button}
              onClick={handleAllRight}
              disabled={left.length === 0}
              aria-label="move all right"
            >
              ≫
            </Button>
            <Button
              variant="outlined"
              size="small"
              className={classes.button}
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0}
              aria-label="move selected right"
            >
              &gt;
            </Button>
            <Button
              variant="outlined"
              size="small"
              className={classes.button}
              onClick={handleCheckedLeft}
              disabled={rightChecked.length === 0}
              aria-label="move selected left"
            >
              &lt;
            </Button>
            <Button
              variant="outlined"
              size="small"
              className={classes.button}
              onClick={handleAllLeft}
              disabled={right.length === 0}
              aria-label="move all left"
            >
              ≪
            </Button>
          </Grid>
        </Grid>
        <Grid item>{customList(right)}</Grid>
      </Grid>
      <Grid container spacing={2} justify="start" alignItems="center">
        <Grid item style={{ height: '920px', width: 'calc(100vw - 320px)' }}>
          <div style={{ height: '920px', width: 'calc(100vw - 320px)' }}>
            {loading ? <Loading /> : <Bar data={data} />}
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default UsersBySpecialization
