import ModulesCreateHOC from './ModulesCreateHOC'
import ModulesEditHOC from './ModulesEditHOC'
import ModulesForm from './ModulesForm'
import ModulesList from './ModulesList'

const ModulesCreate = ModulesCreateHOC(ModulesForm)
const ModulesEdit = ModulesEditHOC(ModulesForm)

export default {
  create: ModulesCreate,
  edit: ModulesEdit,
  list: ModulesList,
}
