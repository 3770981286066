import MediaCreateHOC from './MediaCreateHOC'
import MediaEditHOC from './MediaEditHOC'
import MediaForm from './MediaForm'
import MediaList from './MediaList'

const MediaCreate = MediaCreateHOC(MediaForm)
const MediaEdit = MediaEditHOC(MediaForm)

export default {
  create: MediaCreate,
  edit: MediaEdit,
  list: MediaList,
}
