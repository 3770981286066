import React, { useMemo, useRef } from 'react'
import { useInput } from 'react-admin'
import JoditEditor from 'jodit-react'

export const config = {
  readonly: false, // all options from https://xdsoft.net/jodit/doc/
}

export const TextEditor = (props) => {
  const editor = useRef(null)

  const newConfig = useMemo(() => config, [])

  const {
    input: { name, onChange, value, ...rest },
    isRequired,
  } = useInput(props)

  if (props) {
    return (
      <JoditEditor
        ref={editor}
        config={newConfig}
        value={value ? value : ''}
        tabIndex={1} // tabIndex of textarea
        onChange={onChange}
      />
    )
  }
}
