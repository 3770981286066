export const MEDIA_TYPES = ['eDetailing', 'Prezentacje', 'Wideo', 'Webinaria', 'Pdf', 'Podkast']

export const SURVEY_SHOW_CORRECT_ANSWER_TYPE = ['Nie pokazuj', 'Przy pytaniu', 'Na końcu']
export const SURVEY_TYPE = ['Test', 'Ankieta']
export const SURVEY_QUESTION_TYPE = ['Radio', 'Multiple', 'Open']

export const SETTINGS_TYPE = [
  'Koszt wyświetlenia materiału',
  'Koszt zalogowania do systemu w cyklu 24 godzinnym',
  'Koszt zarejestrowania na platformie',
  'Koszt umówienia wizyty przedstawiciela',
  'Koszt zostawienia „lajka” pod materiałem',
  'Koszt dodania komentarza do materiału',
  'Lista adresów e-mail do powiadomienia po kontakcie',
  'Lista adresów e-mail do powiadomienia po dodaniu komentarza',
]

export const EMAIL_CAMPAIGN_STATUS_DRAFT = 0
export const EMAIL_CAMPAIGN_STATUS_IN_PROGRESS = 1
export const EMAIL_CAMPAIGN_STATUS_DONE = 2
export const EMAIL_CAMPAIGN_STATUS = {
  [EMAIL_CAMPAIGN_STATUS_DRAFT]: 'draft',
  [EMAIL_CAMPAIGN_STATUS_IN_PROGRESS]: 'in progress',
  [EMAIL_CAMPAIGN_STATUS_DONE]: 'done',
}

export const AGREEMENT_TYPES = ['Rejestracja']
