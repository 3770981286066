import React, { useEffect, useState } from 'react'
import { useDataProvider, Loading } from 'react-admin'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'

import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Checkbox from '@material-ui/core/Checkbox'
import Paper from '@material-ui/core/Paper'

import Bar from './Bar'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
  },
  paper: {
    width: 200,
    height: 230,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}))

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1)
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1)
}

const MediaVisitChart = (props) => {
  const dataProvider = useDataProvider()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])

  const initialDateTo = new Date()
  const initialDateFrom = new Date()
  initialDateFrom.setMonth(initialDateTo.getMonth() - 1)

  const [dateTo, setDateTo] = useState(initialDateTo)
  const [dateFrom, setDateFrom] = useState(initialDateFrom)
  const [commentAddActions, setCommentAddActions] = useState(true)
  const [mediaLikeActions, setMediaLikeActions] = useState(true)
  const [favoriteUsersMedia, setFavoriteUsersMedia] = useState(true)

  const classes = useStyles()
  const [checked, setChecked] = useState([])
  const [left, setLeft] = useState([])
  const [right, setRight] = useState([])

  const leftChecked = intersection(checked, left)
  const rightChecked = intersection(checked, right)

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
  }

  const handleAllRight = () => {
    setRight(right.concat(left))
    setLeft([])
  }

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked))
    setLeft(not(left, leftChecked))
    setChecked(not(checked, leftChecked))
  }

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked))
    setRight(not(right, rightChecked))
    setChecked(not(checked, rightChecked))
  }

  const handleAllLeft = () => {
    setLeft(left.concat(right))
    setRight([])
  }

  useEffect(async () => {
    const { data: modules } = await dataProvider.getList('modules', {
      pagination: { page: 1, perPage: 999999 },
      filter: {},
      sort: { field: 'id', order: 'ASC' },
    })

    setRight(modules)
  }, [])

  useEffect(async () => {
    await fetchData()
  }, [left, right, dateTo, dateFrom, commentAddActions, mediaLikeActions, favoriteUsersMedia])

  const fetchData = async () => {
    setLoading(true)

    const mediaIds = []

    if (!right.length) {
      setData([])
    }

    for (const module of right) {
      if (!module.mediaGroups) {
        continue
      }

      for (const [key, mediaGroup] of Object.entries(module.mediaGroups)) {
        if (!mediaGroup.media) {
          continue
        }
        for (const media of mediaGroup.media) {
          mediaIds.push(media.id)
        }
      }
    }

    if (!mediaIds.length) {
      setLoading(false)
      return
    }

    let result = new Map()

    let filter = {
      _dateRange: {
        createdAt: [dateFrom.toISOString().slice(0, 10), dateTo.toISOString().slice(0, 10)],
      },
    }

    filter.media = mediaIds

    const { data: visits } = await dataProvider.getList('media-visit-actions', {
      pagination: { page: 1, perPage: 999999 },
      filter: filter,
      sort: { field: 'id', order: 'ASC' },
    })

    parseActions(visits, 'Wyświetlenia materiałów', result)

    if (commentAddActions) {
      let filter = {
        _dateRange: {
          createdAt: [dateFrom.toISOString().slice(0, 10), dateTo.toISOString().slice(0, 10)],
        },
      }
      filter.media = mediaIds
      const { data } = await dataProvider.getList('comment-add-actions', {
        pagination: { page: 1, perPage: 999999 },
        filter: filter,
        sort: { field: 'id', order: 'ASC' },
      })

      parseActions(data, 'Komentarze', result)
    }

    if (mediaLikeActions) {
      let filter = {
        _dateRange: {
          createdAt: [dateFrom.toISOString().slice(0, 10), dateTo.toISOString().slice(0, 10)],
        },
      }
      filter.media = mediaIds
      const { data } = await dataProvider.getList('media-like-actions', {
        pagination: { page: 1, perPage: 999999 },
        filter: filter,
        sort: { field: 'id', order: 'ASC' },
      })

      parseActions(data, 'Polubienia materiału ', result)
    }

    if (favoriteUsersMedia) {
      let filter = {
        _dateRange: {
          createdAt: [dateFrom.toISOString().slice(0, 10), dateTo.toISOString().slice(0, 10)],
        },
      }
      filter.media = mediaIds
      const { data } = await dataProvider.getList('favorite-users-media', {
        pagination: { page: 1, perPage: 999999 },
        filter: filter,
        sort: { field: 'id', order: 'ASC' },
      })

      parseActions(data, 'Dodane do ulubionych', result)
    }

    setData(Array.from(result.values()))

    setLoading(false)
  }

  const parseActions = (actions, label, parsed) => {
    const result = parsed || new Map()

    for (const item of actions) {
      const mediaId = item.media.id
      const mediaName = item.media.name
        ? `${item.media.name}-${item.media.id}`
        : `none-${item.media.id}`

      if (!result.has(mediaId)) {
        let obj = {}
        obj.media = mediaName
        obj[label] = 1
        result.set(mediaId, obj)
      } else {
        let obj = { ...result.get(mediaId) }
        obj[label] = result.get(mediaId)[label] ? ++result.get(mediaId)[label] : 1
        result.set(mediaId, obj)
      }
    }

    return result
  }

  const customList = (items) => (
    <Paper className={classes.paper}>
      <List dense component="div" role="list">
        {items.map((value) => {
          const labelId = `transfer-list-item-${value.id}-label`

          return (
            <ListItem key={value.id} role="listitem" button onClick={handleToggle(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${value.name}`} />
            </ListItem>
          )
        })}
        <ListItem />
      </List>
    </Paper>
  )

  return (
    <>
      <Grid container spacing={2} justify="start" alignItems="center" className={classes.root}>
        <Grid item>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="yyyy-MM-dd"
              margin="normal"
              id="date-picker-inline1"
              label="Date from"
              value={dateFrom}
              onChange={setDateFrom}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="yyyy-MM-dd"
              margin="normal"
              id="date-picker-inline2"
              label="Date to"
              value={dateTo}
              onChange={setDateTo}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              className={classes.controlsMargin}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item>{customList(left)}</Grid>
        <Grid item>
          <Grid container direction="column" alignItems="center">
            <Button
              variant="outlined"
              size="small"
              className={classes.button}
              onClick={handleAllRight}
              disabled={left.length === 0}
              aria-label="move all right"
            >
              ≫
            </Button>
            <Button
              variant="outlined"
              size="small"
              className={classes.button}
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0}
              aria-label="move selected right"
            >
              &gt;
            </Button>
            <Button
              variant="outlined"
              size="small"
              className={classes.button}
              onClick={handleCheckedLeft}
              disabled={rightChecked.length === 0}
              aria-label="move selected left"
            >
              &lt;
            </Button>
            <Button
              variant="outlined"
              size="small"
              className={classes.button}
              onClick={handleAllLeft}
              disabled={right.length === 0}
              aria-label="move all left"
            >
              ≪
            </Button>
          </Grid>
        </Grid>
        <Grid item>{customList(right)}</Grid>
        {/* <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              fetchData()
            }}
          >
            Update
          </Button>
        </Grid> */}
        <Grid item>
          <FormControlLabel
            control={
              <Switch
                checked={commentAddActions}
                onChange={(e) => setCommentAddActions(!commentAddActions)}
                color="primary"
              />
            }
            label="Komentarze"
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Switch
                checked={mediaLikeActions}
                onChange={(e) => setMediaLikeActions(!mediaLikeActions)}
                color="primary"
              />
            }
            label="Polubienia materiału
            "
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Switch
                checked={favoriteUsersMedia}
                onChange={(e) => setFavoriteUsersMedia(!favoriteUsersMedia)}
                color="primary"
              />
            }
            label="Dodane do ulubionych
            "
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} justify="start" alignItems="center">
        <Grid item style={{ height: '920px', width: 'calc(100vw - 320px)' }}>
          <div style={{ height: '920px', width: 'calc(100vw - 320px)' }}>
            {loading ? <Loading /> : <Bar data={data} />}
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default MediaVisitChart
