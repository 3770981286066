import React from 'react'
import { SimpleForm, TextInput, Edit } from 'react-admin'

const SpecializationsEdit = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="title" />
      </SimpleForm>
    </Edit>
  )
}

export default SpecializationsEdit
