import { TextField, DateField, Datagrid, List } from 'react-admin'

const PdfFilesList = (props) => (
  <List {...props}>
    <Datagrid rowClick={'show'}>
      <TextField source="id" />
      <TextField source="title" />
      <TextField source="identificator" />
      <DateField source="createdAt" />
    </Datagrid>
  </List>
)

export default PdfFilesList
