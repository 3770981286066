import { TextInput, TabbedForm, FormTab, required, FileInput, FileField } from 'react-admin'

const ModulesForm = (props) => {
  return (
    <TabbedForm {...props}>
      <FormTab label="main">
        <TextInput source="title" validate={[required()]} />
        <FileInput source="files" multiple={true} validate={required()}>
          <FileField source="src" title="title" />
        </FileInput>
      </FormTab>
    </TabbedForm>
  )
}

export default ModulesForm
