import { Edit, useNotify, useRedirect } from 'react-admin'

const SurveysEditHOC = (WrappedComponent) => {
  return (props) => {
    const transform = (data) => {
      const newData = {}

      Object.keys(data).forEach((i) => {
        if (i === 'userGroups') {
          newData[i] = data[i].map((item) => (item.id ? item.id : item))
        } else if (typeof data[i] === 'object' && data[i].id) {
          newData[i] = data[i].id
        } else {
          newData[i] = data[i]
        }
      })

      if (newData.id) {
        delete newData.id
      }

      return newData
    }

    return (
      <Edit {...props} transform={transform}>
        <WrappedComponent />
      </Edit>
    )
  }
}

export default SurveysEditHOC
