import React, { useState } from 'react'
import { useForm } from 'react-final-form'
import {
  required,
  Button,
  SaveButton,
  TextInput,
  useCreate,
  useNotify,
  FormWithRedirect,
  FileInput,
  FileField,
  DateInput,
} from 'react-admin'
import IconContentAdd from '@material-ui/icons/Add'
import IconCancel from '@material-ui/icons/Cancel'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import ImagesReferenceInput from '../common/ImagesReferenceInput'

const CertificateQuickCreateButton = ({ onChange }) => {
  const [showDialog, setShowDialog] = useState(false)
  const [create, { loading }] = useCreate('certificates')
  const notify = useNotify()
  const form = useForm()

  const handleClick = () => {
    setShowDialog(true)
  }

  const handleCloseClick = () => {
    setShowDialog(false)
  }

  const transform = (data) => {
    const newData = {}

    Object.keys(data).forEach((i) => {
      if (typeof data[i] === 'object' && data[i].id) {
        newData[i] = data[i].id
      } else {
        newData[i] = data[i]
      }
    })

    if (newData.id) {
      delete newData.id
    }

    return newData
  }

  const handleSubmit = async (values) => {
    create(
      { payload: { data: transform(values) } },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false)
          onChange()
          form.change('certificate', data)
        },
        onFailure: ({ error }) => {
          notify(error.message, 'error')
        },
      }
    )
  }

  return (
    <React.Fragment>
      <Button onClick={handleClick} label="ra.action.create">
        <IconContentAdd />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Create certificate"
      >
        <DialogTitle>Create certificate</DialogTitle>

        <FormWithRedirect
          resource="certificates"
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, pristine, saving }) => (
            <React.Fragment>
              <DialogContent>
                <TextInput source="title" validate={required()} fullWidth />
                <DateInput source="publishDate" validate={required()} fullWidth />
                <TextInput source="supervisorName" validate={required()} fullWidth />
                <TextInput source="medicalChamberNumber" validate={required()} fullWidth />
                <ImagesReferenceInput
                  source="supervisorFacsimile.id"
                  reference="images"
                  fieldName="supervisorFacsimile"
                  label="Supervisor facsimile"
                  isRequired={true}
                />
              </DialogContent>
              <DialogActions>
                <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={loading}>
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </React.Fragment>
          )}
        />
      </Dialog>
    </React.Fragment>
  )
}

export default CertificateQuickCreateButton
