import { ReferenceInput, SelectInput, required } from 'react-admin'
import { useState, useCallback } from 'react'
import { useFormState } from 'react-final-form'
import { makeStyles } from '@material-ui/core/styles'
import { servicesHost } from '../../utils/dataprovider'
import ImagesQuickPreviewButton from './ImagesQuickPreviewButton'
import ImagesQuickCreateButton from './ImagesQuickCreateButton'
import ImageInput from './ImageInput'
import PropTypes from 'prop-types'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
})

const spySubscription = { values: true }

const ImagesReferenceInput = ({ source, reference, fieldName, label, isRequired, ...props }) => {
  const classes = useStyles()
  const [version, setVersion] = useState(0)
  const { values } = useFormState({ subscription: spySubscription })
  const handleChange = useCallback(() => setVersion(version + 1), [version])

  return (
    <div className={classes.root}>
      {!!values[fieldName] && (
        <img
          src={
            values[fieldName].imageFile
              ? values[fieldName].imageFile.src
                ? `${values[fieldName].imageFile.src}`
                : `${values[fieldName].src}`
              : `${servicesHost.host}/uploads/images/${values[fieldName].name}`
          }
          style={{ maxWidth: '60px' }}
        />
      )}
      <ImageInput
        key={version}
        {...props}
        source={source}
        reference={reference}
        allowEmpty
        validate={isRequired && required()}
        toChange={handleChange}
        fieldName={fieldName}
        label={label}
      />
      <ImagesQuickCreateButton
        source={source}
        reference={reference}
        fieldName={fieldName}
        label={label}
        onChange={handleChange}
      />
    </div>
  )
}

ImagesReferenceInput.propTypes = {
  source: PropTypes.string,
  reference: PropTypes.string,
  fieldName: PropTypes.string,
  label: PropTypes.string,
  isRequired: PropTypes.bool,
}

ImagesReferenceInput.defaultProps = {
  source: 'image.id',
  reference: 'images',
  fieldName: 'image',
  label: 'image',
  isRequired: true,
}

export default ImagesReferenceInput
