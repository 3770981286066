import React from 'react'
import {
  SimpleForm,
  TextInput,
  Create,
  useNotify,
  useRedirect,
  AutocompleteInput,
  ReferenceInput,
  required,
  FormDataConsumer,
} from 'react-admin'

const NotificationCampaignsCreate = (props) => {
  const notify = useNotify()
  const redirect = useRedirect()
  const onSuccess = ({ data }) => {
    notify('ra.notification.created', 'info', { smart_count: 1 })
    redirect('list', props.basePath, data.id, data)
  }

  return (
    <Create {...props} onSuccess={onSuccess}>
      <SimpleForm>
        <TextInput fullWidth={true} source="title" validate={[required()]} />
        <TextInput fullWidth={true} source="description" />
        <ReferenceInput
          label="Survey"
          source="survey"
          reference="surveys"
          allowEmpty
          validate={[required()]}
          filterToQuery={(searchText) => ({ _q: { title: searchText } })}
        >
          <AutocompleteInput source="title" optionText="title" />
        </ReferenceInput>
        {/* <FormDataConsumer>
          {({ formData, ...rest }) =>
            !formData.isToAll && (
              <>
                <ReferenceInput
                  label="Specialization"
                  source="specialization.id"
                  reference="specializations"
                >
                  <SelectInput optionText="title" />
                </ReferenceInput>
                <ArrayInput source="selectedEmails">
                  <SimpleFormIterator>
                    <TextInput validate={[email('Invalid email')]} />
                  </SimpleFormIterator>
                </ArrayInput>
              </>
            )
          }
        </FormDataConsumer> */}
      </SimpleForm>
    </Create>
  )
}

export default NotificationCampaignsCreate
