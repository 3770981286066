import React from 'react'
import {
  TextInput,
  BooleanInput,
  DateTimeInput,
  TabbedForm,
  FormTab,
  required,
  ReferenceArrayInput,
  SelectArrayInput,
} from 'react-admin'
import ImagesReferenceInput from '../common/ImagesReferenceInput'
import QuestionsTab from '../common/QuestionsTab'

const ExamsForm = (props) => {
  return (
    <TabbedForm {...props} initialValues={{ examQuestions: [] }}>
      <FormTab label="main">
        <TextInput source="title" validate={[required()]} />
        <BooleanInput source="isEnabled" />
        <ImagesReferenceInput />
        <TextInput multiline source="description" fullwidth />
        <TextInput multiline source="subTitle" fullwidth />
      </FormTab>
      <FormTab label="accesibility">
        <ReferenceArrayInput
          label="User groups"
          source="userGroups"
          reference="user-groups"
          format={(i) => {
            return i && i.map((m) => (m.id ? m.id : m))
          }}
        >
          <SelectArrayInput optionText="title" />
        </ReferenceArrayInput>
      </FormTab>
      <FormTab label="locks">
        <BooleanInput source="isLocked" />
        <TextInput source="lockedText" />
        <DateTimeInput
          source="lockedTo"
          options={{ format: 'DD/MM/YYYY, HH:mm:ss', ampm: false, clearable: true }}
        />
      </FormTab>
      <FormTab label="survey">
        <QuestionsTab source="examQuestions" />
      </FormTab>
    </TabbedForm>
  )
}

export default ExamsForm
